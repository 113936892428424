<template>
  <div class="btn-box f-flex">
    <div class="f-flex f-plr10" v-if="quick">
      <div class="s-t">單註金額：</div>
      <div>
        <input
          :class="{ 'ant-input': true, 'text-input-disabled': disabled }"
          style="width: 100px"
          type="number"
          min="1"
          v-model="amount"
          :disabled="disabled"
          @change="changeAmountEvent($event)"
          @input="changeAmountEvent($event)"
          @keyup.enter="changeAmountEvent($event)"
        />
      </div>
    </div>
    <button
      :class="{
        'btn-blue': !disabled,
        'btn-blue-disabled': disabled,
      }"
      @click="emitConfirmBetEvent"
      :disabled="disabled"
    >
      確認
    </button>
    <button class="btn-disabled" @click="emitClearBetEvent">清除</button>
    <button class="btn-yellow btn-yellow1" @click="emitSwitchQuickBetEvent">
      {{ moldText }}
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import Message from "@/components/library/Message";
export default {
  name: "CommonBetBottom",
  props: {
    /**
     * 單註金額
     */
    amount: {
      type: Number,
      default: () => {
        return null;
      },
    },
    /**
     * 是否快速模式
     */
    quick: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否可以下註 true 不允許
     */
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    let moldText = ref("快速模式");
    const emitConfirmBetEvent = () => {
      if (props.quick && (!props.amount || props.amount <= 0)) {
        Message({ text: "请输入單註金額" });
        return;
      }
      emit("on-confirm-bet", props.amount);
    };
    const emitClearBetEvent = () => {
      emit("on-clear-bet");
    };
    const emitSwitchQuickBetEvent = () => {
      moldText.value = props.quick ? "快速模式" : "一般模式";
      emit("on-switch-quick-bet", !props.quick);
    };
    const changeAmountEvent = (e) => {
      console.log("changeAmountEvent", Number(e.target.value));
      emit("on-change-bet-amount", Number(e.target.value));
    };

    return {
      moldText,
      emitConfirmBetEvent,
      emitClearBetEvent,
      emitSwitchQuickBetEvent,
      changeAmountEvent,
    };
  },
};
</script>