<template>
  <div class="handle-bet">
    <div class="f-flex">
      <div class="bet-t">手動快速下註</div>
      ：{{ title }}
    </div>
    <div class="f-ptb10">
      <textarea
        class="ant-textarea"
        rows="3"
        cols="20"
        style="width: 50%"
        v-model="quickVal"
      ></textarea>
    </div>
    <button class="btn-blue" @click="quickBetSendEvent">發送</button>
    <button class="btn-blue" @click="clearQuickBetValEvent">清除</button>
  </div>
</template>
<script>
/**
 * 手动快读下註
 */
import { ref } from "vue";
import Message from "@/components/library/Message";
export default {
  name: "LhcManualQuick",
  props: {
    title: {
      type: String,
      default:
        "格式：01=100 02=100 10=500(01號100元 02號100元 10號500元)。每一註以空格分隔，前两位为號碼，等號后數字为金額。",
    },
  },
  setup(props, { emit }) {
    let quickVal = ref("");
    const quickBetSendEvent = () => {
      if (!quickVal.value) {
        Message({ text: "请输入下註號碼及金額" });
        return;
      }
      if (quickVal.value.indexOf("=") == -1) {
        Message({ text: "请按照说明格式输入" });
        return;
      }
      emit("quick-bet-click", quickVal.value);
      clearQuickBetValEvent();
    };
    const clearQuickBetValEvent = () => {
      quickVal.value = "";
    };
    return { quickBetSendEvent, clearQuickBetValEvent, quickVal };
  },
};
</script>
<style scoped lang="less">
</style>
